
body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.titulo-destaque {
    background: #09bace;
    text-align: center;
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    margin-top: 195px;
    font-size: 25pt;
}
.titulo-destaque span {
    font-weight: bold;
}

form .item-form {
    margin: 30px 0px;
    color: #808080;
    font-size: 20pt;
}
form .item-form div {
    margin-bottom: 10px;
}

form .item-form fieldset {
    border-radius: 40px;
    border: 2px solid #bbb;
    /* height: 10px; */
}

.toggle-button-group-sauber button{
    margin-right: 1%;
    margin-left: 1%;
    margin-bottom: 10px;
    border-radius: 40px;
    border: 2px solid #bbb;
    width: 18%;
    height: 65px;
    background: transparent;
}

.toggle-button-group-sauber button.Mui-selected {
    background: #09bace;
    border: 2px solid #09bace;
    color: #fff;
}
.toggle-button-group-sauber button.Mui-selected:hover {
    background: #0BbCcF;
}
.toggle-button-group-sauber button:hover {
    background: #e1e1e1;
}

.botao-acao {
    background: transparent;
    border: 2px solid #0bbccf;
    padding: 13px 25px;
    border-radius: 50px;
    font-size: 17pt;
    color: black;
}

.botao-acao:hover {
    background: #b6ebf1;
    cursor: pointer;
}