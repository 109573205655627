html {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #eeee11;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#bg-image::after {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(13px); /* apply the blur */
  pointer-events: none;
}

#bg-image {
  position: fixed;
  background: no-repeat center center;
  background-size: cover;
  opacity: .5;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
